var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVisible
    ? _c(
        "div",
        { staticClass: "particular-clauses-container" },
        [
          _c("p", { staticClass: "font-semibold mt-8" }, [
            _vm._v("Condições Particulares")
          ]),
          _vm.optionsComp && _vm.optionsComp.length
            ? _c(
                "vs-row",
                [
                  _c(
                    "vs-col",
                    { staticClass: "w-full", attrs: { "vs-w": "12" } },
                    [
                      _c("ValidationProvider", {
                        attrs: { name: "Condições Particulares" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var errors = ref.errors
                                return [
                                  _c("v-select", {
                                    staticClass: "w-full particular-clauses",
                                    attrs: {
                                      label: "Name",
                                      multiple: "",
                                      filterable: false,
                                      options: _vm.optionsComp,
                                      "show-no-results": false,
                                      value: _vm.valueData,
                                      loading: _vm.loading,
                                      placeholder:
                                        "Selecione uma ou mais opções",
                                      dir: _vm.$vs.rtl ? "rtl" : "ltr"
                                    },
                                    on: {
                                      input: _vm.addClause,
                                      change: _vm.handleFunctionInput
                                    }
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors[0],
                                          expression: "errors[0]"
                                        }
                                      ],
                                      staticClass: "text-danger text-sm"
                                    },
                                    [
                                      _vm._v(
                                        "\n          " +
                                          _vm._s(errors[0]) +
                                          "\n        "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3135086459
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._l(_vm.value, function(clause, indexKey) {
            return [
              clause &&
              clause.Id &&
              _vm.hasTags(clause.AggregatorClause) &&
              ((_vm.particularClausesInputs(clause.AggregatorClause) || [])
                .length ||
                (_vm.particularClausesTextArea(clause.AggregatorClause) || [])
                  .length)
                ? _c("vs-row", { key: indexKey }, [
                    _c("p", { staticClass: "font-semibold mt-8 w-full" }, [
                      _vm._v("\n        " + _vm._s(clause.Name) + "\n      ")
                    ]),
                    (_vm.particularClausesCampos || []).filter(function(x) {
                      return x.Type != "Automatic"
                    }).length
                      ? _c(
                          "div",
                          [
                            _c(
                              "vs-row",
                              { staticClass: "mt-5" },
                              _vm._l(
                                _vm.particularClausesInputs(
                                  clause.AggregatorClause
                                ),
                                function(input, key) {
                                  return _c(
                                    "vs-col",
                                    {
                                      key: key,
                                      attrs: {
                                        "vs-lg": _vm.getColValue(
                                          _vm.particularClausesInputs(
                                            clause.AggregatorClause
                                          )
                                        ),
                                        "vs-sm": "12"
                                      }
                                    },
                                    [
                                      _c("onpoint-input", {
                                        attrs: {
                                          input: input,
                                          validatorRuleObject: {
                                            required: true,
                                            isNumeric: input.Type == "Number"
                                          }
                                        },
                                        on: { input: _vm.handleFunctionInput }
                                      })
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            ),
                            _vm._l(
                              _vm.particularClausesTextArea(
                                clause.AggregatorClause
                              ),
                              function(input, key) {
                                return _c(
                                  "vs-row",
                                  { key: "textarea" + key },
                                  [
                                    _c(
                                      "vs-col",
                                      { attrs: { "vs-w": "12" } },
                                      [
                                        _c("onpoint-input", {
                                          attrs: {
                                            input: input,
                                            validatorRuleObject: {
                                              required: true
                                            }
                                          },
                                          on: { input: _vm.handleFunctionInput }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }
                            ),
                            _vm.particularClausesRepeater(clause.Id).length
                              ? _vm._l(
                                  _vm.particularClausesRepeater(clause.Id),
                                  function(repeaterObject, repeaterIndex) {
                                    return _c(
                                      "vs-row",
                                      {
                                        key: "div" + repeaterIndex,
                                        staticClass: "mt-5",
                                        attrs: { "vs-type": "flex" }
                                      },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "font-semibold mb-5" },
                                          [_vm._v(_vm._s(repeaterObject.Label))]
                                        ),
                                        _vm._l(
                                          repeaterObject.Repeater,
                                          function(
                                            repeaterInputs,
                                            repeaterKey
                                          ) {
                                            return _c(
                                              "vs-card",
                                              {
                                                key: "repeater" + repeaterKey,
                                                staticClass:
                                                  "pt-3 repeater-clause"
                                              },
                                              [
                                                _c(
                                                  "vs-row",
                                                  _vm._l(
                                                    repeaterInputs,
                                                    function(input, inputKey) {
                                                      return _c(
                                                        "vs-col",
                                                        {
                                                          key:
                                                            "input" + inputKey,
                                                          attrs: {
                                                            "vs-lg": _vm.getColValue(
                                                              repeaterInputs
                                                            ),
                                                            "vs-sm": "12"
                                                          }
                                                        },
                                                        [
                                                          _c("onpoint-input", {
                                                            attrs: {
                                                              input: input,
                                                              validatorRuleObject: {
                                                                required: true,
                                                                isNumeric:
                                                                  input.Type ==
                                                                  "Number"
                                                              }
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.handleFunctionInput
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                                repeaterKey > 0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        attrs: {
                                                          slot: "header"
                                                        },
                                                        slot: "header"
                                                      },
                                                      [
                                                        _c(
                                                          "vs-row",
                                                          {
                                                            attrs: {
                                                              "vs-justify":
                                                                "flex-end"
                                                            }
                                                          },
                                                          [
                                                            _c("vs-button", {
                                                              attrs: {
                                                                icon: "clear"
                                                              },
                                                              nativeOn: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.removeRepeaterObject(
                                                                    {
                                                                      id:
                                                                        clause.Id,
                                                                      repeaterIndex: repeaterIndex,
                                                                      repeaterKey: repeaterKey
                                                                    }
                                                                  )
                                                                }
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        _c(
                                          "vs-col",
                                          {
                                            staticClass: "py-0",
                                            attrs: {
                                              "vs-w": "12",
                                              "vs-justify": "end",
                                              "vs-align": "center",
                                              "vs-type": "flex"
                                            }
                                          },
                                          [
                                            _c(
                                              "vs-button",
                                              {
                                                staticClass: "px-1",
                                                attrs: {
                                                  type: "flat",
                                                  icon: "add_circle"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.addNewLineRepeater(
                                                      {
                                                        params:
                                                          repeaterObject
                                                            .Repeater[0],
                                                        key: repeaterIndex,
                                                        id: clause.Id
                                                      }
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                Adicionar " +
                                                    _vm._s(
                                                      repeaterObject.Label
                                                    ) +
                                                    "\n              "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      2
                                    )
                                  }
                                )
                              : _vm._e()
                          ],
                          2
                        )
                      : _vm._e()
                  ])
                : _vm._e()
            ]
          }),
          !_vm.hideObject && (_vm.value || []).length
            ? _c(
                "vs-row",
                { staticClass: "pt-5" },
                [
                  _c("vs-col", { attrs: { "vs-w": "12" } }, [
                    _c("p", { staticClass: "font-semibold" }, [
                      _vm._v("Objeto das condições particulares")
                    ]),
                    _c("p", {
                      staticClass: "text-objeto p-5",
                      staticStyle: { "font-size": "15px" },
                      domProps: {
                        innerHTML: _vm._s(_vm.particularClausesHtmlFormated)
                      }
                    })
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }